@import "src/styles/variables";

.wrapper {
  background: white;
  height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  height: 420px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  z-index: 1;
  flex-direction: column;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  > div {
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.inviteName {
  text-align: center;
  color: white;
  font-size: 18px;

  > span {
    background: rgb(0 0 0 / 50%);
    border-radius: 6px;
    padding: 4px 8px;
  }
}

.eventName {
  text-align: center;
  font-weight: 600;
  color: black;
  font-size: 42px;
  margin-top: 6px;

  > span {
    // background: rgb(0 0 0 / 50%);
    border-radius: 6px;
    padding: 4px 8px;
  }
}

.countdown {
  text-align: center;
  font-weight: 500;
  color: black;
  font-size: 24px;
  width: max-content;
  margin: 16px auto 16px auto;
  background: rgb(221 234 247);
  border-radius: 6px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  button {
    width: max-content;
    margin-left: 6px;
    margin-right: 6px;
  }
}

.countdownText {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .startingIn {
    font-size: 24px;
  }
}

.cardWrapper {
  max-width: 860px;
  margin: 20px auto;
  display: flex;

  @media (max-width: 960px) {
    flex-direction: column;
  }

  > div {
    &:first-child {
      @media (max-width: 960px) {
        width: 100%;
      }
      width: 60%;
    }

    &:last-child {
      @media (max-width: 960px) {
        width: 100%;
      }
      width: 40%;
    }

    padding-left: 6px;
    padding-right: 6px;

    > div {
      border: 1px solid #eee;
      padding: 20px;
      margin-bottom: 14px;
      border-radius: 8px;
    }
  }

  .eventDescription {
    white-space: pre-line;
    font-size: 16px;
    font-weight: 400;
  }
}

.feedback {
  display: flex;
  flex-direction: column;

  .title {
    //font-size: 22px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .description {
    //font-size: 18px;
    font-weight: 400;
    margin-bottom: 14px;
  }

  button {
    width: max-content;
  }
}

.powered {
  margin-bottom: 12px;
  text-align: center;

  a {
    color: black;
    border-bottom: 1px solid black;
  }
}

.card {
  width: 100%;
  align-self: center;
  background: white;
  text-align: center;
  max-width: 460px;
  padding: 24px;
  border-radius: $border-radius;
  margin: 20px auto;

  .loginButton {
    margin-top: 30px;
    width: 100%;
  }
}

.note {
  text-align: center;
  color: white;

  a {
    color: white;
    text-decoration: underline;

    &:hover {
      color: $secondary-color;
      text-decoration: underline;
    }
  }
}

.logo {
  > svg {
    max-width: 200px;
    display: block;
    margin: 0 auto;
  }
}

.spaceLogo {
  text-align: center;
  margin: 0 auto 12px auto;

  > img {
    max-height: 60px;
    max-width: 100%;
  }
}

.order {
  flex-grow: 1;
}
.footer {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;

  > a {
    color: black;
    display: flex;
    align-items: center;

    &:hover {
      color: black;
    }
    svg {
      fill: black;
      width: 80px;
    }
  }
}

.page {
  width: 100%;
  height: 100%;
  background: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    width: 600px;
  }
}
