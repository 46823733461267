.cls1 {
  fill: #fc4c00;
}
.cls2 {
  fill: #ffcd03;
}
.cls3 {
  fill: #14b9ff;
}
.cls4 {
  fill: #1ddb00;
}
.cls5 {
  fill: #ffffff;
}
.cls6 {
  fill: #1943ff;
}
.cls7 {
  fill: #9c5fff;
}
