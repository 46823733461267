@import "src/styles/variables";

.container {
  width: 100%;
  height: 100%;

  dyte-setup-screen {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("./bg10.jpg");
  }
  > div {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("./bg10.jpg");
  }
}
