@import "src/styles/variables";

.avatar {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;

  > div {
    margin-top: 12px;
  }
}

.gridItem {
  background-color: $background-color;
  padding: 12px;
  display: flex;
  align-items: center;

  .previewImage {
    cursor: pointer;
    width: 100%;
  }
}

.mode {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: center;
  align-items: center;

  > span {
    white-space: normal;
    line-height: 21px;
    margin-bottom: 6px;
  }
  .modeIcon {
    width: 140px;
    margin-top: 12px;
    border-radius: 4px;
    height: 40px;
    margin-bottom: 6px;
  }
}

.segment {
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
  > div {
    > label {
      width: 50%;
    }
  }
  .segmentItem {
    padding: 6px;
    //height: 100px;

    > div > p {
      line-height: normal;
      white-space: normal;
      text-align: left;
    }
  }
}
