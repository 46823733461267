@import "src/styles/variables";

.container {
  width: 100%;
  height: 100%;
  background: rgb(248, 249, 250);
  overflow-y: auto;
}

.inner {
  width: 100%;
  // max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  min-height: 100vh;

  display: flex;
}

.content {
  margin-left: 18px;
  margin-right: 18px;
  margin-top: 18px;
  margin-bottom: 18px;
  width: 100%;
}

.meeting {
  border: 0.0625rem solid #dee2e6;
  border-radius: 0.5rem;
  height: calc(100vh - 108px);
  width: 100%;
  overflow: hidden;
}

.guestMeeting {
  min-height: -webkit-fill-available;
  height: 100%;
  width: 100%;
}

.meetingContainer {
  width: 100%;
  margin-left: 18px;
  margin-right: 18px;
  margin-top: 4px;
  margin-bottom: 18px;
}

.articles {
  display: flex;

  > div {
    &:first-child {
      width: 70%;
      padding-right: 18px;
    }
    &:last-child {
      width: 30%;
    }
  }
}

.roomHeader {
  display: flex;
  align-items: center;
  > img {
    height: 38px;
    width: 64px;
    object-fit: cover;
  }
}

.grow {
  flex-grow: 1;
}
