@import "../../../styles/variables";

@import "src/styles/variables";

.container {
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.logo {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  > svg {
    max-height: 100px;
    max-width: 100px;
  }
}
